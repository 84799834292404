import React, { useState } from 'react';
import { Mail, Lock, X, AlertCircle, Loader2, ArrowLeft } from 'lucide-react';
import { requestAccessCode, verifyAccessCode } from '@/services/userAccess.service';
import { RecoveryModal } from './RecoveryModal';
import { cn } from '@/lib/utils';
import { useAuth } from '@/context/AuthContext';

interface AccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUnlock?: () => void;
}

export function AccessModal({ isOpen, onClose, onUnlock }: AccessModalProps) {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [step, setStep] = useState<'email' | 'code'>('email');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showRecovery, setShowRecovery] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { startSession } = useAuth();

  const handleSubmitEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await requestAccessCode(email);
      if (response.success) {
        setStep('code');
        setIsSuccess(true);
        if (!response.data?.hasActiveCode) {
          setError('Te hemos enviado un código de acceso por email');
        }
      } else {
        setError(response.message);
        if (response.data?.blocked) {
          setTimeout(() => {
            setError(null);
            setEmail('');
          }, 3000);
        }
      }
    } catch (err) {
      console.error('Error al solicitar código:', err);
      setError('Error al procesar la solicitud');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitCode = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await verifyAccessCode(email, code);
      if (response.success && response.data?.expiresAt) {
        startSession(email, response.data.expiresAt);
        if (onUnlock) {
          onUnlock();
        }
        onClose();
      } else {
        setError(response.message);
        if (response.data?.blocked) {
          setStep('email');
          setEmail('');
          setCode('');
        }
      }
    } catch (err) {
      console.error('Error al verificar código:', err);
      setError('Error al verificar el código');
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    setStep('email');
    setCode('');
    setError(null);
    setIsSuccess(false);
  };

  if (!isOpen) return null;

  if (showRecovery) {
    return (
      <RecoveryModal
        email={email}
        onClose={() => setShowRecovery(false)}
        onBack={handleBack}
      />
    );
  }

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-gray-900 rounded-xl p-6 w-full max-w-md relative">
        {step === 'code' && (
          <button
            onClick={handleBack}
            className="absolute top-4 left-4 text-gray-400 hover:text-white transition-colors"
          >
            <ArrowLeft className="w-6 h-6" />
          </button>
        )}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors"
        >
          <X className="w-6 h-6" />
        </button>

        <div className="text-center mb-6">
          <h3 className="text-2xl font-bold text-white mb-2">
            Desbloquea todas las funcionalidades
          </h3>
          <p className="text-gray-400">
            {step === 'email' 
              ? 'Introduce tu email para acceder'
              : 'Introduce tu código de acceso'
            }
          </p>
        </div>

        {error && (
          <div className={cn(
            "mb-4 p-3 rounded-lg flex items-center gap-2",
            isSuccess 
              ? "bg-emerald-500/10 border border-emerald-500/20 text-emerald-400"
              : "bg-red-500/10 border border-red-500/20 text-red-400"
          )}>
            <AlertCircle className="w-5 h-5 flex-shrink-0" />
            <p className="text-sm">{error}</p>
          </div>
        )}

        {step === 'email' ? (
          <form onSubmit={handleSubmitEmail} className="space-y-4">
            <div className="relative">
              <Mail className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Tu email"
                className="w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:border-blue-500"
                required
              />
            </div>
            <button
              type="submit"
              disabled={loading}
              className="w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
            >
              {loading && <Loader2 className="w-5 h-5 animate-spin" />}
              Continuar
            </button>
          </form>
        ) : (
          <form onSubmit={handleSubmitCode} className="space-y-4">
            <div className="relative">
              <Lock className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
              <input
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value.toUpperCase())}
                placeholder="Código de acceso"
                className="w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:border-blue-500"
                maxLength={8}
                required
              />
            </div>
            <button
              type="submit"
              disabled={loading}
              className="w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
            >
              {loading && <Loader2 className="w-5 h-5 animate-spin" />}
              Verificar código
            </button>

            <div className="text-center">
              <button
                type="button"
                onClick={() => setShowRecovery(true)}
                className="text-sm text-blue-400 hover:text-blue-300 transition-colors"
              >
                ¿No has recibido el código?
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}