import { createClient } from '@supabase/supabase-js';
import { retryWithBackoff } from '@/utils/retry';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true
  },
  db: {
    schema: 'public'
  },
  realtime: {
    params: {
      eventsPerSecond: 10
    }
  }
});

export async function supabaseQuery<T>(
  queryFn: () => Promise<T>,
  maxRetries = 3
): Promise<T> {
  return retryWithBackoff(queryFn, {
    maxRetries,
    baseDelay: 1000,
    onError: (error) => {
      console.error('Supabase query error:', error);
    }
  });
}