import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '@/components/Header';
import { BackButton } from '@/components/shared/BackButton';
import { usePageTracking } from '@/hooks/usePageTracking';

export default function Layout() {
  const location = useLocation();
  const isHome = location.pathname === '/';

  // Initialize page tracking
  usePageTracking();

  return (
    <div className="min-h-screen bg-gray-900">
      <Header />
      {!isHome && <BackButton />}
      <main className="pt-16">
        <Outlet />
      </main>
    </div>
  );
}