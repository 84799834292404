import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { supabase, supabaseQuery } from '@/lib/supabase';
import { useAuth } from '@/context/AuthContext';

export function usePageTracking() {
  const location = useLocation();
  const { email } = useAuth();

  useEffect(() => {
    const updatePageTracking = async () => {
      if (!email) return;

      try {
        await supabaseQuery(async () => {
          const { error } = await supabase
            .from('interaccion_bot')
            .upsert(
              {
                email,
                pagina: location.pathname,
                fecha_actualizacion: new Date().toISOString()
              },
              {
                onConflict: 'email'
              }
            );

          if (error) throw error;
        });
      } catch (err) {
        // Log error but don't show to user since this is non-critical
        console.warn('Error updating page tracking:', err);
      }
    };

    updatePageTracking();
  }, [location.pathname, email]);
}