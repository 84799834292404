import React, { Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Layout from '@/components/layout/Layout';
import LoadingSpinner from '@/components/shared/LoadingSpinner';

// Lazy load route components
const Home = React.lazy(() => import('@/pages/Home'));
const MortgageCalculator = React.lazy(() => import('@/pages/MortgageCalculator'));
const MortgageComparator = React.lazy(() => import('@/pages/MortgageComparator'));
const MaximumCalculator = React.lazy(() => import('@/pages/MaximumCalculator'));
const CombinedTools = React.lazy(() => import('@/pages/CombinedTools'));
const Analysis = React.lazy(() => import('@/pages/Analysis'));
const ArticlePage = React.lazy(() => import('@/pages/ArticlePage'));
const ArticlesPage = React.lazy(() => import('@/pages/ArticlesPage'));
const BrokerPage = React.lazy(() => import('@/pages/BrokerPage'));

// Suspense wrapper component
const SuspenseWrapper = ({ children }: { children: React.ReactNode }) => (
  <Suspense fallback={<LoadingSpinner />}>
    {children}
  </Suspense>
);

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <SuspenseWrapper><Home /></SuspenseWrapper>
      },
      {
        path: 'calculadora-hipoteca',
        element: <SuspenseWrapper><MortgageCalculator /></SuspenseWrapper>
      },
      {
        path: 'comparador-hipotecas',
        element: <SuspenseWrapper><MortgageComparator /></SuspenseWrapper>
      },
      {
        path: 'cuanto-puedo-pedir',
        element: <SuspenseWrapper><MaximumCalculator /></SuspenseWrapper>
      },
      {
        path: 'herramientas-hipotecarias',
        element: <SuspenseWrapper><CombinedTools /></SuspenseWrapper>
      },
      {
        path: 'analisis',
        element: <SuspenseWrapper><Analysis /></SuspenseWrapper>
      },
      {
        path: 'articulos',
        element: <SuspenseWrapper><ArticlesPage /></SuspenseWrapper>
      },
      {
        path: 'articulos/:slug',
        element: <SuspenseWrapper><ArticlePage /></SuspenseWrapper>
      },
      {
        path: 'broker',
        element: <SuspenseWrapper><BrokerPage /></SuspenseWrapper>
      }
    ]
  }
]);