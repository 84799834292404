import React, { useState } from 'react';
import { Mail, ArrowLeft, X, AlertCircle, Loader2 } from 'lucide-react';
import { requestRecoveryCode } from '@/services/userAccess.service';
import { cn } from '@/lib/utils';

interface RecoveryModalProps {
  email: string;
  onClose: () => void;
  onBack: () => void;
}

export function RecoveryModal({ email, onClose, onBack }: RecoveryModalProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await requestRecoveryCode(email);
      if (response.success) {
        setSuccess(true);
        setTimeout(() => {
          onBack();
        }, 3000);
      } else {
        setError(response.message);
      }
    } catch (err) {
      setError('Error al procesar la solicitud de recuperación');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-gray-900 rounded-xl p-6 w-full max-w-md relative">
        <div className="absolute top-4 right-4 flex items-center gap-2">
          <button
            onClick={onBack}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <ArrowLeft className="w-6 h-6" />
          </button>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="text-center mb-6">
          <h3 className="text-2xl font-bold text-white mb-2">
            Solicitar nuevo código
          </h3>
          <p className="text-gray-400">
            Te enviaremos un nuevo código a tu email
          </p>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-500/10 border border-red-500/20 rounded-lg flex items-center gap-2 text-red-400">
            <AlertCircle className="w-5 h-5 flex-shrink-0" />
            <p className="text-sm">{error}</p>
          </div>
        )}

        {success ? (
          <div className="text-center">
            <div className="mb-4 p-3 bg-emerald-500/10 border border-emerald-500/20 rounded-lg flex items-center gap-2 text-emerald-400">
              <AlertCircle className="w-5 h-5 flex-shrink-0" />
              <p className="text-sm">Nuevo código enviado correctamente</p>
            </div>
            <p className="text-gray-400 text-sm">
              Redirigiendo...
            </p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="relative">
              <Mail className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
              <input
                type="email"
                value={email}
                disabled
                className="w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white opacity-50 cursor-not-allowed"
              />
            </div>
            <button
              type="submit"
              disabled={loading}
              className="w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
            >
              {loading && <Loader2 className="w-5 h-5 animate-spin" />}
              Solicitar nuevo código
            </button>
          </form>
        )}
      </div>
    </div>
  );
}