import React, { createContext, useContext, useState } from 'react';
import type { CalculatorState } from '@/types/calculator.types';

interface AnalysisContextType {
  isAnalyzing: boolean;
  startAnalysis: () => void;
  stopAnalysis: () => void;
  analysisData: {
    mortgage: Partial<CalculatorState>;
    maximum: Partial<CalculatorState>;
  } | null;
  setAnalysisData: (data: {
    mortgage: Partial<CalculatorState>;
    maximum: Partial<CalculatorState>;
  }) => void;
}

const AnalysisContext = createContext<AnalysisContextType | undefined>(undefined);

export function AnalysisProvider({ children }: { children: React.ReactNode }) {
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [analysisData, setAnalysisData] = useState<{
    mortgage: Partial<CalculatorState>;
    maximum: Partial<CalculatorState>;
  } | null>(null);

  const startAnalysis = () => setIsAnalyzing(true);
  const stopAnalysis = () => setIsAnalyzing(false);

  return (
    <AnalysisContext.Provider value={{
      isAnalyzing,
      startAnalysis,
      stopAnalysis,
      analysisData,
      setAnalysisData
    }}>
      {children}
    </AnalysisContext.Provider>
  );
}

export function useAnalysis() {
  const context = useContext(AnalysisContext);
  if (context === undefined) {
    throw new Error('useAnalysis must be used within an AnalysisProvider');
  }
  return context;
}