import { supabase } from '@/lib/supabase';
import { sendAccessCode, sendRecoveryCode } from './email.service';

interface UserAccessResponse {
  success: boolean;
  message: string;
  data?: {
    verified?: boolean;
    blocked?: boolean;
    blockedUntil?: Date;
    hasActiveCode?: boolean;
    expiresAt?: string;
    isRegistered?: boolean;
  };
}

export async function requestAccessCode(email: string): Promise<UserAccessResponse> {
  try {
    const { data: existingUser, error: queryError } = await supabase
      .from('acceso_usuarios')
      .select('*')
      .eq('email', email)
      .single();

    if (queryError && queryError.code !== 'PGRST116') {
      throw queryError;
    }

    if (existingUser?.verificado) {
      if (existingUser.bloqueado_hasta && new Date(existingUser.bloqueado_hasta) > new Date()) {
        return {
          success: false,
          message: 'Cuenta bloqueada temporalmente',
          data: {
            blocked: true,
            blockedUntil: new Date(existingUser.bloqueado_hasta),
            isRegistered: true
          }
        };
      }

      if (existingUser.codigo_permanente) {
        return {
          success: true,
          message: 'Por favor, introduce tu código de acceso',
          data: {
            hasActiveCode: true,
            isRegistered: true,
            verified: true
          }
        };
      }
    }

    const temporaryCode = Math.random().toString(36).substring(2, 10).toUpperCase();
    const expirationTime = new Date();
    expirationTime.setHours(expirationTime.getHours() + 1);

    if (existingUser) {
      await supabase
        .from('acceso_usuarios')
        .update({
          codigo_temporal: temporaryCode,
          codigo_temporal_expira: expirationTime.toISOString(),
          intentos_fallidos: 0,
          bloqueado_hasta: null
        })
        .eq('email', email);
    } else {
      await supabase
        .from('acceso_usuarios')
        .insert({
          email,
          codigo_temporal: temporaryCode,
          codigo_temporal_expira: expirationTime.toISOString()
        });
    }

    const emailSent = await sendAccessCode(email, temporaryCode);
    if (!emailSent) {
      throw new Error('Error al enviar el email');
    }

    return {
      success: true,
      message: existingUser?.verificado 
        ? 'Se ha enviado un código temporal a tu email'
        : 'Código de acceso enviado correctamente',
      data: {
        hasActiveCode: false,
        isRegistered: Boolean(existingUser?.verificado)
      }
    };

  } catch (error) {
    console.error('Error al solicitar código:', error);
    return {
      success: false,
      message: 'Error al procesar la solicitud'
    };
  }
}

export async function verifyAccessCode(email: string, code: string): Promise<UserAccessResponse> {
  try {
    const { data: user, error: queryError } = await supabase
      .from('acceso_usuarios')
      .select('*')
      .eq('email', email)
      .single();

    if (queryError) throw queryError;

    if (!user) {
      return {
        success: false,
        message: 'Usuario no encontrado'
      };
    }

    if (user.bloqueado_hasta && new Date(user.bloqueado_hasta) > new Date()) {
      return {
        success: false,
        message: 'Cuenta bloqueada temporalmente',
        data: {
          blocked: true,
          blockedUntil: new Date(user.bloqueado_hasta)
        }
      };
    }

    const isValidPermanent = user.verificado && user.codigo_permanente === code;
    const isValidTemporary = user.codigo_temporal === code && 
                           user.codigo_temporal_expira && 
                           new Date(user.codigo_temporal_expira) > new Date();

    if (!isValidPermanent && !isValidTemporary) {
      const intentos = (user.intentos_fallidos || 0) + 1;
      const bloqueado_hasta = intentos >= 3 
        ? new Date(Date.now() + 60 * 60000).toISOString() 
        : null;

      await supabase
        .from('acceso_usuarios')
        .update({
          intentos_fallidos: intentos,
          bloqueado_hasta
        })
        .eq('email', email);

      return {
        success: false,
        message: intentos >= 3 
          ? 'Cuenta bloqueada por múltiples intentos fallidos'
          : 'Código incorrecto',
        data: {
          blocked: intentos >= 3,
          blockedUntil: bloqueado_hasta ? new Date(bloqueado_hasta) : undefined
        }
      };
    }

    // Si el código temporal es válido, convertirlo en permanente
    if (isValidTemporary) {
      await supabase
        .from('acceso_usuarios')
        .update({
          verificado: true,
          codigo_permanente: code, // Usar el mismo código temporal como permanente
          codigo_temporal: null,
          codigo_temporal_expira: null,
          intentos_fallidos: 0,
          bloqueado_hasta: null,
          ultima_actividad: new Date().toISOString()
        })
        .eq('email', email);
    }

    // Actualizar última actividad
    await supabase
      .from('acceso_usuarios')
      .update({
        intentos_fallidos: 0,
        bloqueado_hasta: null,
        ultima_actividad: new Date().toISOString()
      })
      .eq('email', email);

    // Establecer expiración de sesión a 1 hora
    const expiresAt = new Date(Date.now() + 60 * 60000).toISOString();

    return {
      success: true,
      message: 'Código verificado correctamente',
      data: {
        verified: true,
        expiresAt
      }
    };

  } catch (error) {
    console.error('Error al verificar código:', error);
    return {
      success: false,
      message: 'Error al verificar el código'
    };
  }
}

export async function requestRecoveryCode(email: string): Promise<UserAccessResponse> {
  try {
    const { data: user, error: queryError } = await supabase
      .from('acceso_usuarios')
      .select('*')
      .eq('email', email)
      .single();

    if (queryError) throw queryError;

    if (!user) {
      return {
        success: false,
        message: 'Usuario no encontrado'
      };
    }

    if (!user.verificado) {
      return {
        success: false,
        message: 'Usuario no verificado'
      };
    }

    if (user.bloqueado_hasta && new Date(user.bloqueado_hasta) > new Date()) {
      return {
        success: false,
        message: 'Cuenta bloqueada temporalmente',
        data: {
          blocked: true,
          blockedUntil: new Date(user.bloqueado_hasta)
        }
      };
    }

    const temporaryCode = Math.random().toString(36).substring(2, 10).toUpperCase();
    const expirationTime = new Date();
    expirationTime.setHours(expirationTime.getHours() + 1);

    await supabase
      .from('acceso_usuarios')
      .update({
        codigo_temporal: temporaryCode,
        codigo_temporal_expira: expirationTime.toISOString(),
        intentos_fallidos: 0,
        bloqueado_hasta: null
      })
      .eq('email', email);

    const emailSent = await sendRecoveryCode(email, temporaryCode);
    if (!emailSent) {
      throw new Error('Error al enviar el email');
    }

    return {
      success: true,
      message: 'Se ha enviado un nuevo código a tu email',
      data: {
        hasActiveCode: false,
        isRegistered: true
      }
    };

  } catch (error) {
    console.error('Error al solicitar código de recuperación:', error);
    return {
      success: false,
      message: 'Error al procesar la solicitud de recuperación'
    };
  }
}