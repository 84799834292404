import React, { useEffect } from 'react';
import { ChatbotHighlight } from './ChatbotHighlight';

declare global {
  interface Window {
    ktt10?: {
      setup: (config: {
        id: string;
        accountId: string;
        color: string;
      }) => void;
    };
  }
}

const CHATBOT_CONFIG = {
  id: "n6rD6exrUA",
  accountId: "1785021",
  color: "#9936D6"
};

export function ChatbotProvider({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    // Load chatbot script
    const script = document.createElement('script');
    script.src = "https://app.chatgptbuilder.io/webchat/plugin.js?v=5";
    script.async = true;
    script.onload = () => {
      if (window.ktt10) {
        window.ktt10.setup(CHATBOT_CONFIG);
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <ChatbotHighlight />
      {children}
    </>
  );
}