export function register() {
  // Only register service worker in production and when not in WebContainer
  if (
    process.env.NODE_ENV === 'production' && 
    'serviceWorker' in navigator &&
    !window.location.hostname.includes('stackblitz.io')
  ) {
    window.addEventListener('load', () => {
      const swUrl = '/sw.js';
      navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
          registration.addEventListener('updatefound', () => {
            const installingWorker = registration.installing;
            if (installingWorker == null) return;

            installingWorker.addEventListener('statechange', () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  // New content available
                  if (confirm('New content is available! Click OK to refresh.')) {
                    window.location.reload();
                  }
                }
              }
            });
          });
        })
        .catch((error) => {
          console.error('Error during service worker registration:', error);
        });
    });
  }
}