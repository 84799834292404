import React, { createContext, useContext, useEffect, useState } from 'react';
import { supabase } from '@/lib/supabase';

interface AuthState {
  isVerified: boolean;
  email: string | null;
  expiresAt: string | null;
  accessCode: string | null; // Añadimos el código de acceso
  lastLoginAttempt?: {
    timestamp: string;
    userAgent: string;
    ipAddress?: string;
  };
}

interface AuthContextType {
  isVerified: boolean;
  email: string | null;
  startSession: (email: string, expiresAt: string) => void;
  endSession: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [session, setSession] = useState<AuthState>(() => {
    const stored = localStorage.getItem('user_session');
    if (!stored) return { isVerified: false, email: null, expiresAt: null };

    const parsedSession = JSON.parse(stored);
    if (parsedSession.expiresAt && new Date(parsedSession.expiresAt) < new Date()) {
      localStorage.removeItem('user_session');
      return { isVerified: false, email: null, expiresAt: null };
    }

    return parsedSession;
  });

  useEffect(() => {
    const checkSession = async () => {
      if (!session.email || !session.expiresAt) return;

      const expirationDate = new Date(session.expiresAt);
      if (expirationDate < new Date()) {
        localStorage.removeItem('user_session');
        setSession({
          isVerified: false,
          email: null,
          expiresAt: null
        });
        return;
      }

      const { data, error } = await supabase
        .from('acceso_usuarios')
        .select('verificado')
        .eq('email', session.email)
        .single();

      if (error || !data?.verificado) {
        localStorage.removeItem('user_session');
        setSession({
          isVerified: false,
          email: null,
          expiresAt: null
        });
      }
    };

    checkSession();
    const interval = setInterval(checkSession, 60000);

    return () => clearInterval(interval);
  }, [session.email, session.expiresAt]);

  const startSession = async (email: string, expiresAt: string, accessCode: string) => {
  const oneHourFromNow = new Date(Date.now() + 60 * 60 * 1000).toISOString();
  
  const loginAttempt = {
    timestamp: new Date().toISOString(),
    userAgent: window.navigator.userAgent,
  };

  await supabase.from('sesiones_usuario').insert({
    email,
    fecha_inicio: loginAttempt.timestamp,
    fecha_expiracion: oneHourFromNow,
    user_agent: loginAttempt.userAgent,
    activa: true
  });
  
  const sessionData = {
    isVerified: true,
    email,
    expiresAt: oneHourFromNow,
    accessCode, // Guardamos el código de acceso
    lastLoginAttempt: loginAttempt
  };
  
  localStorage.setItem('user_session', JSON.stringify(sessionData));
  setSession(sessionData);
};

  const endSession = async () => {
    if (session.email) {
      // Mark session as inactive in Supabase
      await supabase
        .from('sesiones_usuario')
        .update({ activa: false, fecha_fin: new Date().toISOString() })
        .eq('email', session.email)
        .eq('activa', true);
    }

    localStorage.removeItem('user_session');
    setSession({
      isVerified: false,
      email: null,
      expiresAt: null
    });
  };

  return (
    <AuthContext.Provider value={{
      isVerified: session.isVerified,
      email: session.email,
      startSession,
      endSession
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}