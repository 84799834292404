import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { cn } from '@/lib/utils';

interface BackButtonProps {
  className?: string;
}

export function BackButton({ className }: BackButtonProps) {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(-1)}
      className={cn(
        "fixed top-20 left-4 z-40",
        "px-3 py-1.5", // Reduced padding
        "bg-gray-900/90 hover:bg-gray-800/90",
        "text-white text-sm", // Reduced text size
        "rounded-lg",
        "backdrop-blur-sm",
        "border border-gray-800",
        "shadow-lg",
        "transition-all duration-300",
        "flex items-center gap-1.5", // Reduced gap
        "group",
        "scale-80", // Added 20% size reduction
        className
      )}
    >
      <ArrowLeft className="w-3.5 h-3.5 transition-transform group-hover:-translate-x-1" /> {/* Reduced icon size */}
      <span>Volver</span>
    </button>
  );
}