import React from 'react';
import { cn } from '@/lib/utils';

export function ChatbotHighlight() {
  return (
    <div className="fixed bottom-6 right-6 z-40 pointer-events-none">
      <div className={cn(
        "w-14 h-14 rounded-full",
        "bg-purple-600/20 backdrop-blur-sm",
        "animate-aurora-pulse"
      )} />
    </div>
  );
}
