import emailjs from '@emailjs/browser';

const EMAILJS_SERVICE_ID = 'service_febtkz7';
const EMAILJS_TEMPLATE_ID = 'template_2ou8sgk';
const EMAILJS_PUBLIC_KEY = 'KwEK4P0gva__b5P78';

export async function sendAccessCode(email: string, code: string): Promise<boolean> {
  try {
    const response = await emailjs.send(
      EMAILJS_SERVICE_ID,
      EMAILJS_TEMPLATE_ID,
      {
        to_email: email,
        code: code,
        type: 'acceso',
        message: `Tu código de acceso es: ${code}. Este código expirará en 1 hora.`
      },
      EMAILJS_PUBLIC_KEY
    );

    return response.status === 200;
  } catch (error) {
    console.error('Error sending email:', error);
    return false;
  }
}

export async function sendRecoveryCode(email: string, code: string): Promise<boolean> {
  try {
    const response = await emailjs.send(
      EMAILJS_SERVICE_ID,
      EMAILJS_TEMPLATE_ID,
      {
        to_email: email,
        code: code,
        type: 'recuperación',
        message: `Tu código de recuperación es: ${code}. Este código expirará en 1 hora.`
      },
      EMAILJS_PUBLIC_KEY
    );

    return response.status === 200;
  } catch (error) {
    console.error('Error sending recovery email:', error);
    return false;
  }
}